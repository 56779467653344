export default {
    OS_NAME: process.env.VUE_APP_OS_NAME,
    ACCENT_COLOR: "#0366d6",
    SUPPORTED_DEVICES: [
        {
            name: "Pixel 8 Pro",
            model: "husky",
        }
    ],
    RELEASE_VARIANTS: {
        grapheneos: {
            description: "Minimal version without Google apps or services.",
            suffix: "",
        },
    },
    DONATION_LINKS: [],
};
